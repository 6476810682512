import { useCallback, useContext, useLayoutEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { SECTION_LAYOUT } from "@helpers/constants/generic"
import NUMBERS from "@helpers/constants/numbers"
import getBreadcrumbs from "@helpers/dataFunctions/getBreadcrumbs"
import { getAllFleixbleBlocksData } from "@helpers/flexibleApi"
import ApplicationContext from "@utils/application-context/applicationContext"
import { findFieldPaywallRoles } from "@utils/paywallUtilities"
import _ from "lodash"
import { dataLayerPush } from "@utils/gtmutils"

export type LazyLoadingComponentType = {
  pageBlocks: Array<any>
  nid: number
  pathAlias: string
  isHomepage: boolean
  targetId: number
  paywall: boolean
  paywallNotice: boolean
  paywallRoles: string
  paywallText: string
}

export const useLazyLoadingComponents = (
  componentsBlocks: LazyLoadingComponentType,
  props: any,
): any => {
  const [loading, setLoading] = useState<boolean>(false)
  const [components, setComponents] = useState<any>({
    blocks: [],
  })
  const queryString = window?.location?.search ? window.location.search?.replace("?", "&") : ""
  const [componentLoadingFinished, setComponentLoadingFinished] = useState<boolean>(false)
  const [breadcrumbsLoaded, setBreadcrumbsLoaded] = useState<boolean>(false)
  const [isPaywall3Available, setIsPaywall3Available] = useState<boolean>(false)
  const { applicationConfiguration } = useContext(ApplicationContext)
  const common: any = useSelector((state: any) => state.common)

  let allFieldPaywallRoles: any = []

  const handleScroll = (htmlElement: any, searchValue: any, value: any) => {
    const position = htmlElement.getBoundingClientRect()
    const floatingElement = document.querySelector(".floatingBanner")
    const eyebrowMenuElement = document.querySelector(".eyebrow-menu")

    const floatingElementHeight = floatingElement
      ? floatingElement.getBoundingClientRect().height
      : 0
    const eyebrowMenuElementHeight = eyebrowMenuElement
      ? eyebrowMenuElement.getBoundingClientRect().height
      : 0

    const scrollPosition =
      position.top + window.scrollY - (floatingElementHeight + eyebrowMenuElementHeight)
    // Check if the scroll position is within the document bounds
    const maxScroll = document.documentElement.scrollHeight - window.innerHeight
    const finalScrollPosition = Math.min(Math.max(scrollPosition, 0), maxScroll)

    window.scrollTo({
      left: position.left + window.scrollX,
      top: finalScrollPosition,
      behavior: "smooth",
    })

    if (searchValue?.includes("acc")) {
      const classes = htmlElement?.getAttribute("class")
      if (classes?.includes("accordion") || classes?.includes("accordion-item")) {
        const getChildren1 = htmlElement?.children[0]?.children[0]
        getChildren1?.classList.remove("collapsed")
        const getChildren2 = htmlElement?.children[1]
        getChildren2?.classList.add("show")
      }
    }

    if (searchValue?.includes("tab")) {
      const element = document.getElementById(value)
      if (element) {
        const clickEvent = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
        element.dispatchEvent(clickEvent)
      }
    }
  }

  /**
   * Checks for a stored login event in sessionStorage and pushes it to the data layer if found.
   * If a stored event is found, it is parsed, pushed to the data layer, and then removed from sessionStorage.
   */
  const checkAndPushStoredLoginEvent = () => {
    // Retrieve the stored event data from sessionStorage
    const storedEvent = sessionStorage.getItem("loginEvent")

    // Check if there is any stored event data
    if (storedEvent) {
      // Parse the stored event data from JSON string to an object
      const { event, data } = JSON.parse(storedEvent)

      // Push the event and its data to the data layer
      dataLayerPush(event, data)

      // Remove the stored event data from sessionStorage after it has been pushed
      sessionStorage.removeItem("loginEvent")
    }
  }

  // scroll to anchor block
  useLayoutEffect(() => {
    if (componentLoadingFinished && !loading && breadcrumbsLoaded) {
      const { location } = window
      const hashedValue = location?.hash || ""
      let value = ""

      if (hashedValue) {
        value = hashedValue?.includes("#")
          ? hashedValue?.split("#")?.[1]?.split("/")?.[0]
          : hashedValue?.split("/")?.[0]
      } else {
        const searchValue = location?.search
        value = searchValue
          ? new URLSearchParams(searchValue).get("acc") ||
            new URLSearchParams(searchValue).get("tab") ||
            ""
          : ""
        value = value?.includes("/") ? value?.split("/")?.[0] : value
      }

      const htmlElement = document.getElementById(value)
      if (htmlElement) {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (entry?.target === htmlElement) {
              handleScroll(htmlElement, location?.search, value)
            }
          }
        })
        resizeObserver.observe(htmlElement)
        setTimeout(() => {
          handleScroll(htmlElement, location?.search, value)
        }, 100)
      }
    }

    // Call the function to push the login event in data layer after loading is finished
    checkAndPushStoredLoginEvent()
  }, [componentLoadingFinished, loading, breadcrumbsLoaded])

  const iterateBlocksForPage = useCallback(async () => {
    setComponentLoadingFinished(false)
    setBreadcrumbsLoaded(false)
    if (componentsBlocks && componentsBlocks.pageBlocks.length) {
      const clientComponentLimit = parseInt(
        process.env.NEXT_PUBLIC_CLIENT_COMPONENT_CHUNK_SIZE || "",
      )
      const clientComponentChunkSize: number = Number.isInteger(clientComponentLimit)
        ? clientComponentLimit
        : 3
      const batches = _.chunk(componentsBlocks.pageBlocks, clientComponentChunkSize)

      let componentList: any[] = []
      let count = 0
      for (const batch of batches) {
        try {
          setLoading(true)
          const chunkComponent = await Promise.all(batch.map((f: any) => fetchBlockDetails(f)))
          chunkComponent.map((cList: Array<any>) => cList.map((c: any) => componentList.push(c)))

          setComponents({
            blocks: [...componentList],
            ...props,
          })

          // lastly load breadcrumbs (false - don't load || true - load)
          count++
          if (count === batches.length) {
            setComponentLoadingFinished(true)
            setLoading(false)
          }
        } catch (error) {
          setLoading(false)
          console.error("Failed to load components!")
        }
      }
    } else {
      setComponentLoadingFinished(true)
    }
  }, [componentsBlocks, componentsBlocks.pageBlocks])

  const checkForHeroBanner = (
    pageBlocks: Array<any>,
    ignoreHomepageCheck = false,
  ): { isHeroBannerExist: boolean; isRotatingHBExists: boolean } => {
    let isHeroBannerExist = false
    let isRotatingHBExists = false

    pageBlocks.forEach((pageBlock: Array<any>, idx: number) => {
      pageBlock.forEach((block: any, index: number) => {
        if (
          !isHeroBannerExist &&
          !(!ignoreHomepageCheck && props.isHomepage) &&
          idx === 0 &&
          index === 0 &&
          block?.type === "hero_banner"
        ) {
          isHeroBannerExist = true
        }
        if (
          !isRotatingHBExists &&
          !(!ignoreHomepageCheck && props.isHomepage) &&
          idx === 0 &&
          index === 0 &&
          block?.type === "rotating_hero_banner"
        ) {
          isRotatingHBExists = true
        }
      })
    })

    return { isHeroBannerExist, isRotatingHBExists }
  }

  // paywall
  useMemo(async () => {
    if (componentLoadingFinished && breadcrumbsLoaded) {
      // paywall 1.0
      if (props.paywall && !components.blocks.find((b: Array<any>) => b?.[0]?.type === "paywall")) {
        components.blocks.push([
          {
            column: SECTION_LAYOUT.ONE_COL,
            type: "paywall",
            background: "white",
            url: "",
            label: "",
            region: NUMBERS.ZERO,
            weight: NUMBERS.ZERO,
            stickyStyle: "",
            columnWidths: undefined,
          },
        ])

        setComponents({
          blocks: [...components.blocks],
          ...props,
        })
      }

      // paywall notice 2.0
      if (
        props.paywallNotice &&
        !components.blocks.find((b: Array<any>) => b?.[0]?.type === "paywall-notice")
      ) {
        const paywallPosition =
          applicationConfiguration?.siteConfig?.content_protection_position ?? "top"
        const { isHeroBannerExist, isRotatingHBExists } = checkForHeroBanner(
          componentsBlocks.pageBlocks,
          true,
        )

        if (components.blocks.length > 1) {
          if (paywallPosition === "top" || paywallPosition === "both") {
            const paywallNotice = {
              column: SECTION_LAYOUT.ONE_COL,
              type: "paywall-notice",
              background: "white",
              url: "",
              label: "",
              region: NUMBERS.ZERO,
              weight: NUMBERS.ZERO,
              stickyStyle: "",
              columnWidths: "",
              paywallRoles: props.paywallRoles,
            }
            if (!isHeroBannerExist && !isRotatingHBExists) {
              props.isHomepage
                ? components.blocks.splice(NUMBERS.ZERO, NUMBERS.ZERO, [paywallNotice])
                : components.blocks.splice(NUMBERS.ONE, NUMBERS.ZERO, [paywallNotice])
            } else {
              props.isHomepage
                ? components.blocks.splice(NUMBERS.ONE, NUMBERS.ZERO, [paywallNotice])
                : components.blocks.splice(NUMBERS.TWO, NUMBERS.ZERO, [paywallNotice])
            }
          }
          if (paywallPosition === "bottom" || paywallPosition === "both") {
            components.blocks.push([
              {
                column: SECTION_LAYOUT.ONE_COL,
                type: "paywall-notice",
                background: "white",
                url: "",
                label: "",
                region: NUMBERS.ZERO,
                weight: NUMBERS.ZERO,
                stickyStyle: "",
                columnWidths: "",
                paywallRoles: props.paywallRoles,
              },
            ])
          }
        } else {
          components.blocks.push([
            {
              column: SECTION_LAYOUT.ONE_COL,
              type: "paywall-notice",
              background: "white",
              url: "",
              label: "",
              region: NUMBERS.ZERO,
              weight: NUMBERS.ZERO,
              stickyStyle: "",
              columnWidths: "",
              paywallRoles: props.paywallRoles,
            },
          ])
        }

        setComponents({
          blocks: [...components.blocks],
          ...props,
        })
      }

      allFieldPaywallRoles.push(findFieldPaywallRoles(components.blocks))
      if (common?.paywall3Roles && common?.paywall3Roles?.length) {
        allFieldPaywallRoles.push(common?.paywall3Roles)
      }
      allFieldPaywallRoles = allFieldPaywallRoles.flat()
      allFieldPaywallRoles = Array.from(
        new Set(allFieldPaywallRoles?.filter((item: any) => item !== undefined)),
      )

      if (
        !components.blocks.find((b: Array<any>) => b?.[0]?.type === "paywall-field-notice") &&
        allFieldPaywallRoles.length &&
        Object.keys(applicationConfiguration)?.length
      ) {
        let allFieldPaywallRolesText =
          allFieldPaywallRoles?.length > 0 ? allFieldPaywallRoles?.join(", ") : ""
        allFieldPaywallRolesText =
          allFieldPaywallRolesText && componentsBlocks.paywallRoles
            ? `${allFieldPaywallRolesText}, ${componentsBlocks.paywallRoles}`
            : allFieldPaywallRolesText
        allFieldPaywallRolesText = Array.from(new Set(allFieldPaywallRolesText?.split(", ")))?.join(
          ", ",
        )

        if (allFieldPaywallRoles?.length > 0) {
          const paywallPosition =
            applicationConfiguration?.siteConfig?.content_protection_position ?? "top"
          const { isHeroBannerExist, isRotatingHBExists } = checkForHeroBanner(
            componentsBlocks.pageBlocks,
            true,
          )
          if (components.blocks.length > 1) {
            if (paywallPosition === "top" || paywallPosition === "both") {
              const paywallNotice = {
                column: SECTION_LAYOUT.ONE_COL,
                background: "white",
                url: "",
                label: "",
                region: NUMBERS.ZERO,
                weight: NUMBERS.ZERO,
                stickyStyle: "",
                columnWidths: "",
                type: "paywall-field-notice",
                data: allFieldPaywallRoles,
                allFieldPaywallRolesText,
              }

              if (!isHeroBannerExist && !isRotatingHBExists) {
                props.isHomepage
                  ? components.blocks.splice(NUMBERS.ZERO, NUMBERS.ZERO, [paywallNotice])
                  : components.blocks.splice(NUMBERS.ONE, NUMBERS.ZERO, [paywallNotice])
              } else {
                props.isHomepage
                  ? components.blocks.splice(NUMBERS.ONE, NUMBERS.ZERO, [paywallNotice])
                  : components.blocks.splice(NUMBERS.TWO, NUMBERS.ZERO, [paywallNotice])
              }
            }
            if (paywallPosition === "bottom" || paywallPosition === "both") {
              components.blocks.push([
                {
                  column: SECTION_LAYOUT.ONE_COL,
                  background: "white",
                  url: "",
                  label: "",
                  region: NUMBERS.ZERO,
                  weight: NUMBERS.ZERO,
                  stickyStyle: "",
                  columnWidths: "",
                  type: "paywall-field-notice",
                  data: allFieldPaywallRoles,
                  allFieldPaywallRolesText,
                },
              ])
            }
          } else {
            components.blocks.push([
              {
                column: SECTION_LAYOUT.ONE_COL,
                background: "white",
                url: "",
                label: "",
                region: NUMBERS.ZERO,
                weight: NUMBERS.ZERO,
                stickyStyle: "",
                columnWidths: "",
                type: "paywall-field-notice",
                data: allFieldPaywallRoles,
                allFieldPaywallRolesText,
              },
            ])
          }

          setComponents({
            blocks: [...components.blocks],
            ...props,
          })

          setIsPaywall3Available(true)
        }
      }
    }
  }, [componentLoadingFinished, breadcrumbsLoaded, common?.paywall3Roles, applicationConfiguration])

  // breadcrumbs for slug pages
  useMemo(async () => {
    let isHeroBannerExist = false
    let isRotatingHBExists = false
    if (
      components &&
      components.blocks &&
      componentLoadingFinished &&
      !components.blocks.find((b: Array<any>) => b?.[0]?.type === "breadcrumb")
    ) {
      if (props.isHomepage) {
        setBreadcrumbsLoaded(true)
        return
      }
      const refererData = {
        refererUrl: props?.refererUrl,
        goback_details_enable: applicationConfiguration?.siteConfig?.goback_details_enable,
        goback_details_target_url: applicationConfiguration?.siteConfig?.goback_details_target_url,
        goback_details_button_label_text:
          applicationConfiguration?.siteConfig?.goback_details_button_label_text,
        goback_details_button_position:
          applicationConfiguration?.siteConfig?.goback_details_button_position,
      }

      // transform the query param for product family pages
      const transformQuery = (input: string) => {
        const prefix = "/products/"
        if (input?.startsWith(prefix)) {
          const suffix = input?.slice(prefix?.length)
          const endIndex = suffix?.indexOf("/")
          const extracted = endIndex !== -1 ? suffix?.substring(0, endIndex) : suffix
          const output = `&brand-id=${extracted}`
          return output
        }
        return input
      }

      const breadcrumbsData =
        props?.pageAlias === "product-family" && queryString === ""
          ? await getBreadcrumbs(
              `/${props?.pageAlias}${transformQuery(window.location.pathname)}`,
              null,
              refererData,
            )
          : await getBreadcrumbs(`/${props?.pageAlias}${queryString}`, null, refererData)
      componentsBlocks.pageBlocks.map((pageBlocks: Array<any>, idx: number) => {
        pageBlocks.map((b: any, index: number) => {
          if (
            !isHeroBannerExist &&
            !props.isHomepage &&
            idx === NUMBERS.ZERO &&
            index === NUMBERS.ZERO
          ) {
            isHeroBannerExist = b?.type === "hero_banner"
          }
          if (
            !isRotatingHBExists &&
            !props.isHomepage &&
            idx === NUMBERS.ZERO &&
            index === NUMBERS.ZERO
          ) {
            isRotatingHBExists = b?.type === "rotating_hero_banner"
          }
        })
      })

      if (isHeroBannerExist || isRotatingHBExists) {
        components.blocks.splice(NUMBERS.ONE, NUMBERS.ZERO, [
          {
            type: "breadcrumb",
            data: breadcrumbsData,
          },
        ])
      } else {
        components.blocks.unshift([
          {
            type: "breadcrumb",
            data: breadcrumbsData,
          },
        ])
      }

      setComponents({
        blocks: [...components.blocks],
        ...props,
      })
      setBreadcrumbsLoaded(true)
    }
  }, [componentLoadingFinished])

  const fetchBlockDetails = useCallback((block: any) => {
    if (
      Array.isArray(block) &&
      block.length > 0 &&
      block[0]["data"] !== null &&
      block[0]["data"] !== undefined
    ) {
      return [block]
    }

    const blocks = [block]
    return getAllFleixbleBlocksData(
      blocks,
      componentsBlocks.nid,
      componentsBlocks.targetId,
      componentsBlocks.paywallText,
    )
  }, [])

  return {
    loading,
    components,
    isPaywall3Available,
    iterateBlocksForPage,
  }
}
